.field-tip-wrapper {
    display: flex;
}

.field-tip {
    display: inline-flex;
    align-items: center;
}

.field-tip_stretched {
    display: flex;
    justify-content: space-between;
}

.field-tip__action_without-vert-padding {
    padding: 0 8px;
}
