@import '@hh.ru/magritte-ui/breakpoints';

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    gap: 12px;
}

.row_disable-on-xs {
    display: initial;

    .screen-gt-xs({
        display: flex;
    });
}

.row_align-items-start {
    align-items: start;
}

.row_align-items-center {
    align-items: center;
}

.row_justify-content-start {
    justify-content: flex-start;
}

.row_justify-content-center {
    justify-content: center;
}

.row_gap-0 {
    gap: 0;
}

.row_gap-4 {
    gap: 4px;
}
