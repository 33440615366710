.root {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-icon {
    position: relative;
}

.wrapper-icon {
    position: relative;
    left: 8px;
}

.loading {
    left: 0;
}
