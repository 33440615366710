@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/base-variables';
@import '@hh.ru/magritte-ui/breakpoints';

.similar-vacancy {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding: 12px;
    border-radius: 8px;
    justify-content: space-between;
    background: @magritte-color-base-white;
    transition: box-shadow 0.2s;
}

.similar-vacancy:hover {
    border-color: @magritte-color-base-graphite-85;
    box-shadow: @magritte-shadow-level-1-x @magritte-shadow-level-1-x @magritte-shadow-level-1-blur
        @magritte-shadow-level-1-spread @magritte-shadow-level-1-color;
}
