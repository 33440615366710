.pfp-liquidity-banner-title {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.pfp-liquidity-banner-subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 12px;
}
