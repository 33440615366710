@import '@hh.ru/magritte-ui/base-variables';

.competition-stats {
    display: grid;
    white-space: nowrap;
    align-items: center;
    gap: 4px 24px;
    grid-template-columns: repeat(2, min-content);
    grid-template-rows: repeat(2, min-content);
    grid-auto-flow: column;
}

.mobile {
    gap: 8px;
    grid-auto-flow: row;
}

.wide-desktop {
    border: 1px solid @magritte-color-base-graphite-90;
    border-radius: 8px;
    padding: 16px;
}
