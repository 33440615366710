@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/rounded-corner-base';
@import '~bloko/common/styles/button-base';
@import '~bloko/common/styles/media/_screen';
@import '~src/styles/mixins/invisible-scrollbar';
@import '@hh.ru/magritte-ui/base-variables';
@import '@hh.ru/magritte-ui/tokens';

@parent-padding-xs: 16px;
@parent-padding: 28px;
@shadow-width: 6px;

.employer-cards-wrapper {
    overflow: hidden;
    position: relative;
    margin: 0 -@parent-padding-xs;

    @media @screen-gt-xs {
        @margin-sum: @parent-padding + 20px + @shadow-width;

        margin: 0 -@margin-sum;
        padding: 0 (20px + @shadow-width);
    }
}

.employer-cards-wrapper.employer-cards-wrapper_employer-view {
    margin: 0;
    padding: 0;
}

.employer-cards-scrollbox {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 0 0 0 @parent-padding-xs;

    @media @screen-gt-xs {
        padding: 0 0 0 @parent-padding;

        .invisible-scrollbar();
    }
}

.employer-cards-scrollbox.employer-cards-scrollbox_employer-view {
    .invisible-scrollbar();

    padding: 0;
}

.employer-cards-slider {
    display: flex;

    &::after {
        content: '';
        flex-shrink: 0;
        display: block;
        width: @parent-padding-xs;

        @media @screen-gt-xs {
            width: @parent-padding;
        }
    }
}

.employer-cards-slider.employer-cards-slider_employer-view {
    display: flex;

    &::after {
        width: 0;
    }
}

.employer-card {
    flex-grow: 1;
    min-width: 150px;
    max-width: 310px;
    padding: 0 12px;
    box-sizing: border-box;
    border-radius: @rounded-corner-default;
    color: @color-white;
    margin-right: 8px;

    &:hover,
    &:focus,
    &:active {
        color: @color-white;
    }

    &:last-child {
        margin-right: 0;
    }
}

.employer-card.employer-card_employer-view {
    background: @magritte-color-base-blue-96;
    color: @color-gray-50;
    border-radius: 16px;
    width: 180px;
    max-width: none;
    min-width: auto;
}

.employer-card-rating {
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    background: linear-gradient(to top right, #576fff 0%, #c667ff 100%);
}

.employer-card-hr-brand {
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    background: linear-gradient(to top right, #ff884d 0%, #ffc83a 100%);
}

.employer-card-employer-reviews {
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    background: linear-gradient(to top right, #69bfff 0%, #1793ec 100%);
}

.employer-card-insider-interview {
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    background: linear-gradient(to top right, #fc3e77 0%, #f88db4 100%);
}

.employer-card-special-project {
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    background: linear-gradient(243deg, #5ac8ff 0%, #50b5e8 100%);
}

.employer-card-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.employer-card-title.employer-card-title_employer-view {
    color: @color-special-black-jack;
}

.employer-card-image-wrapper {
    flex-grow: 0;
    flex-shrink: 0;
    align-self: flex-start;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background: @color-white;
    position: relative;
    margin-left: 8px;
}

.employer-card-image-wrapper.employer-card-image-wrapper_employer-view {
    background: none;
}

.employer-card-image {
    position: absolute;
    left: 4px;
    top: 4px;
    width: 16px;
    height: 16px;
    background: no-repeat center center;
}

.employer-card-image-rating {
    background-image: url('./employers-ratings.svg');
}

.employer-card-image-rating.employer-card-image_employer-view {
    background-image: url('./employerView/employers-ratings.svg');
}

.employer-card-image-hr-brand {
    background-image: url('./hr-brand.svg');
}

.employer-card-image-hr-brand.employer-card-image_employer-view {
    background-image: url('./employerView/hr-brand.svg');
}

.employer-card-image-employer-reviews {
    background-image: url('./employer-reviews.svg');
}

.employer-card-image-employer-reviews.employer-card-image_employer-view {
    background-image: url('./employerView/employer-reviews.svg');
}

.employer-card-image-insider-interview {
    background-image: url('./insider-interview.svg');
}

.employer-card-image-insider-interview.employer-card-image_employer-view {
    background-image: url('./employerView/insider-interview.svg');
}

.employer-card-image-special-project {
    background-image: url('./special-project.svg');
}

.employer-card-image-special-project.employer-card-image_employer-view {
    background-image: url('./employerView/special-project.svg');
}

.employer-card-image-it-accreditation.employer-card-image_employer-view {
    background-image: url('./employerView/it-accreditation.svg');
}

.employer-cards-slider-control-left,
.employer-cards-slider-control-right {
    display: none;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    background: @color-white;
    border-radius: 50%;
    box-shadow: 0 2px 6px fade(@color-gray-80, 25%);
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 2;
    transition:
        opacity 0.2s ease-in-out,
        visibility 0.2s;
    visibility: hidden;
    opacity: 0;

    @media @screen-gt-xs {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.employer-cards-slider-control-right {
    right: @shadow-width;
}

.employer-cards-slider-control-right-shown {
    visibility: visible;
    opacity: 1;
}

.employer-cards-slider-control-left {
    left: @shadow-width;
}

.employer-cards-slider-control-left-shown {
    visibility: visible;
    opacity: 1;
}

.employer-cards-slider-control-left-shown + .employer-cards-slider-control-left-grad {
    display: block;
}

.employer-cards-slider-control-left.employer-cards-slider-control-left_employer-view {
    left: 0;
    box-shadow: none;
    background: @color-gray-10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.employer-cards-slider-control-right-shown + .employer-cards-slider-control-right-grad {
    display: block;
}

.employer-cards-slider-control-left-grad {
    width: 80px;
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 55%,
        rgba(255, 255, 255, 0) 100%
    );
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 1;
}

.employer-cards-slider-control-right.employer-cards-slider-control-right_employer-view {
    right: 0;
    box-shadow: none;
    background: @color-gray-10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.employer-cards-slider-control-right-grad {
    width: 80px;
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 45%,
        rgba(255, 255, 255, 1) 100%
    );
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    height: 100%;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 1;
}

.employer-magritte-card {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    display: flex;
    background-color: @magritte-color-background-secondary;

    &.employer-magritte-card_url {
        background-color: @magritte-color-background-accent-secondary;
    }
}

.employer-magritte-icon {
    margin: auto;
    width: 24px;
    height: 24px;
    mask-size: 100%;
    mask-repeat: no-repeat;
    background-color: @magritte-color-icon-primary;

    &.employer-magritte-icon_url {
        background-color: @magritte-color-icon-accent;
    }
}

.employer-magritte-icon-rating {
    mask-image: url('./magritte-employers-ratings.svg');
}

.employer-magritte-icon-hr-brand {
    mask-image: url('./magritte-hr-brand.svg');
}

.employer-magritte-icon-employer-reviews {
    mask-image: url('./magritte-employer-reviews.svg');
}

.employer-magritte-icon-insider-interview {
    mask-image: url('./magritte-insider-interview.svg');
}

.employer-magritte-icon-it-accreditation {
    mask-image: url('./magritte-it-accreditation.svg');
}

.employer-magritte-icon-special-project {
    mask-image: url('./magritte-special-project.svg');
}
