@import '~bloko/common/styles/media/_screen';

.wrapper {
    display: inline-block;
}

.batch-size {
    @media @screen-lt-s {
        display: none;
    }
}
