@import '~bloko/common/styles/media/_screen';
@import '@hh.ru/magritte-ui/breakpoints';

.wrapper {
    @media @screen-gt-xs {
        max-width: 420px;
    }
}

.inputs-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 20px 0;

    .screen-gt-s({
        flex-direction: row;
    });
}
