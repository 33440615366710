@import '@hh.ru/magritte-ui/base-variables';

.rating-chooser {
    display: flex;
    gap: 24px;
}

.rating-chooser-radio {
    display: none;
}

.rating-chooser-item {
    cursor: pointer;
    opacity: 0.5;
    filter: grayscale(1);
    transition: opacity @magritte-semantic-animation-ease-in-out-100-duration
        @magritte-semantic-animation-ease-in-out-tiger-100-timing-function;
}

.rating-chooser-item:hover,
.rating-chooser-item:has(input:checked) {
    filter: none;
    opacity: 1;
}
