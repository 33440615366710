.product-cell {
    padding: 12px 0;
    display: flex;
    gap: 12px;
}

.icon {
    min-width: 40px;
    min-height: 40px;
    align-self: flex-start;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.free {
    background: url('images/free.svg');
}

.standart {
    background: url('images/standart.svg');
}

.standart-plus {
    background: url('images/standart_plus.svg');
}

.premium {
    background: url('images/premium.svg');
}

.anonymous {
    background: url('images/anonymous.svg');
}

.advertising {
    background: url('images/advertising.svg');
}

.business {
    background: url('images/business.svg');
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title-price {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.description-count {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    gap: 4px;
}

.description {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
}

.expandable-text {
    cursor: pointer;
}

.amount {
    text-align: right;
    min-width: 59px;
}
