@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.budget__status-hint {
    color: @color-orange-70;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.budget-amount {
    display: flex;
    align-items: baseline;
    gap: 4px;
    flex-direction: column;

    @media @screen-gt-xs {
        flex-direction: row;
    }
}
