@import '@hh.ru/magritte-ui/base-variables';

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: @magritte-static-space-600;
    min-height: 40px;
}

.actions {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}
