@import '@hh.ru/magritte-ui/base-variables';

.loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.empty-placeholder-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: @magritte-core-space-4-x;
}
