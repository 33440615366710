@import '@hh.ru/magritte-ui/base-variables';

.banner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: @magritte-static-space-400;
}

.mobile {
    flex-direction: column;
    gap: @magritte-static-space-500;
    align-items: flex-start;
}
