@import '~bloko/common/styles/media/_screen';
@import '~src/styles/mixins/shadow-block';

.wrapper {
    .shadow-block();

    padding: @shadow-block-padding;

    @media @screen-gt-xs {
        padding: @shadow-block-desktop-padding;
    }

    @media print {
        .shadow-block-clear();

        padding: 0;
    }
}

.wrapper-flat {
    @media @screen-gt-xs {
        .shadow-block();

        padding: @shadow-block-desktop-padding;
    }

    @media print {
        .shadow-block-clear();

        padding: 0;
    }
}

.block-employer {
    @media print {
        padding-bottom: 30px;
    }
}

.question {
    margin-top: 20px;
}
