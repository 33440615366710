@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/spacing-base';

.banners-header {
    padding: 3 * @spacing-base;
    color: @color-gray-50;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.banners-header-text {
    display: flex;
    align-items: center;
}

.banner {
    color: @color-gray-50;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.banner-content {
    flex-grow: 1;
}

.banner-icon {
    flex-shrink: 1;
    margin-top: 3px;
}

.highlighted-interval {
    border: 1px solid @color-gray-20;
    border-radius: 8px;
    padding: 12px 0 12px 24px;
    margin-right: -15px;
    position: relative;
}

.highlighted-interval::before {
    position: absolute;
    top: 12px;
    bottom: 12px;
    left: 12px;
    content: '';
    display: block;
    border-radius: 7px;
    width: 4px;
}

.highlighted-interval_bad::before {
    background: @color-red-60;
}

.highlighted-interval_good::before {
    background: @color-green-60;
}

.highlighted-interval_normal::before {
    background: @color-orange-60;
}

.list {
    list-style-type: disc;
    margin-left: 22px;
}

.advice-banner {
    color: @color-violet-50;
    display: flex;
    align-items: center;
}

.canvac-banner-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
