@import '~bloko/common/styles/_abstract-control';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/_layers';

.hiring-plan-number-wrapper {
    display: flex;
    gap: @control-padding-horizontal;
}

.quantity-container {
    margin-left: @control-border-width;
    margin-top: -30px;
    margin-bottom: 10px;
    position: relative;
    cursor: text;
    pointer-events: none;
    z-index: @above-content;
}

.amount {
    display: inline-block;
    height: 0;
    padding-left: @control-padding-horizontal;
    overflow: hidden;
}

.text {
    color: @color-gray-80;
    margin-left: 4px;
}
