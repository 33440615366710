@import '@hh.ru/magritte-ui/base-variables';
@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.container {
    display: flex;
    gap: 8px;
    align-items: center;
    box-sizing: border-box;
    padding-left: 12px;
    transition: all 0.25s ease;

    &.dragging {
        background-color: @magritte-color-base-graphite-95;
        border: 1px solid @magritte-color-base-graphite-80;
        box-shadow: 2px 20px 30px @magritte-color-base-graphite-95;
    }

    &.drag-over {
        border: 2px dashed @magritte-color-stroke-accent;
    }
}

.tags-wrapper {
    margin-left: auto;

    @media @screen-magritte-gt-xs {
        margin-left: unset;
        margin-right: auto;
    }
}

.actions-wrapper {
    display: flex;
    gap: 4px;

    > form {
        display: none;
    }

    @media @screen-magritte-gt-s {
        gap: 12px;
    }
}
