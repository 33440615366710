@import '@hh.ru/magritte-ui/base-variables';
@import '@hh.ru/magritte-ui/breakpoints';

.search-snippet-box {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.search-snippet-control {
    flex-shrink: 0;
}

.search-snippet-card {
    pointer-events: none;
}
