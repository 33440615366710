@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/base-variables';

.competition-stats-row {
    display: flex;
    flex-direction: row;
    gap: @magritte-static-space-600;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.competition-stats-column {
    width: 100%;
}

.bar-chart-header {
    padding: @magritte-static-space-300;
    gap: @magritte-static-space-200;
    display: flex;
    flex-direction: column;
}

.low {
    color: @magritte-color-text-positive;
}

.medium {
    color: @magritte-color-text-warning;
}

.high {
    color: @magritte-color-text-negative;
}
