@import '../../bloko/common/styles/media/_screen';

.invisible-scrollbar() {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
