@import '@hh.ru/magritte-ui/base-variables';

.vacancy-key-info {
    line-height: 1.9;
}

.vacancy-key-info-item {
    vertical-align: middle;
    margin-right: @magritte-static-space-250;
}

.vacancy-key-info-dot {
    vertical-align: middle;
    margin-right: @magritte-static-space-250;
}

.vacancy-key-info-item_max-length {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px;
    display: inline-block;
}
