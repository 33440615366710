@import '@hh.ru/magritte-ui/breakpoints';

.competito-card-heading {
    display: flex;
    column-gap: 4px;
    flex-wrap: wrap;

    .screen-gt-xs({        
        flex-wrap: nowrap;
    });
}
