@import '@hh.ru/magritte-ui/base-variables';

@column-date-width: 164px;

.timeline {
    padding: @magritte-core-space-6-x;
    border: 1px solid @magritte-color-base-graphite-90;
    border-radius: @magritte-core-border-radius-4-x;
}

.timeline-item {
    display: flex;
    position: relative;
}

.timeline-item-column {
    flex-direction: column;
    gap: @magritte-core-space-2-x;
}

.timeline-item-date-time {
    flex: 0 0 @column-date-width;
}

.timeline-item-date-time-row {
    display: flex;
    gap: @magritte-core-space-2-x;
}

.timeline-item-date-time,
.timeline-item-text {
    display: flex;
    flex-direction: column;
    gap: @magritte-core-space-2-x;
}

.timeline-item-text {
    position: relative;
    white-space: pre-line;
}

.timeline-item-text-padding {
    padding-left: @magritte-core-space-10-x;
}

.timeline-progress::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background: @magritte-color-base-graphite-55;
    left: @column-date-width;
    top: 0;
}

.timeline-item.timeline-item:first-child::before {
    top: 8px;
}

.timeline-item + .timeline-item {
    padding-top: @magritte-core-space-8-x;
}

.timeline-circle::before {
    content: '';
    width: 9px;
    height: 9px;
    position: absolute;
    border-radius: 50%;
    background: @magritte-color-base-graphite-55;
    left: -4px;
    top: 6px;
}

.timeline-no-data {
    text-align: center;
    padding: @magritte-core-space-4-x 0;
}

.timeline-expand-button {
    padding-top: @magritte-core-space-6-x;
}
