@gap: 12px;
@icon-size: 24px;

.wrapper {
    display: flex;
    gap: @gap;
    align-items: center;

    > div {
        flex: 1 0 calc((100% - @icon-size - 2 * @gap) / 2);
        max-width: calc((100% - @icon-size - 2 * @gap) / 2);
    }
}
