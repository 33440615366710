.icon-betta {
    @top-offset: -16px;

    position: absolute;
    top: @top-offset;
    right: 60px;
    opacity: 0.19;
    clip-path: inset(abs(@top-offset) 0 0 0);
}

.title-container {
    display: flex;
    align-items: center;
    column-gap: 6px;
}

.title {
    // Служит для создания контекста наложения, чтобы текст
    // был поверх икноки
    position: relative;
}
