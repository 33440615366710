@import '@hh.ru/magritte-ui/base-variables';

.description-container {
    display: flex;
    flex-direction: column;
    gap: @magritte-static-space-600;
}

.description-item {
    display: flex;
    gap: @magritte-static-space-300;
    align-items: center;
}
