.tooltip-onboarding-container {
    overflow: hidden;
}

.tooltip-onboarding-img {
    width: 100%;
    min-height: 196px;
    border-radius: 12px;
}

.tooltip-onboarding-list {
    list-style: disc;
    padding-left: 15px;
}

.tooltip-onboarding-button-icon {
    position: relative;
    top: 3px;
    margin-left: 5px;
}
