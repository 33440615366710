/* stylelint-disable selector-nested-pattern */

@import '@hh.ru/magritte-ui/tokens';

@transition-property: color, background, transform, box-shadow;

.nav {
    width: 100%;
    display: flex;
    gap: @magritte-core-space-1-x;
    padding: @magritte-core-space-1-x;
    // stylelint-disable declaration-property-value-allowed-list
    background: @magritte-color-component-segmented-background-content;
    border: solid 1px @magritte-color-component-segmented-stroke-content;
    // stylelint-enable declaration-property-value-allowed-list
    border-radius: @magritte-semantic-border-radius-form;
    box-sizing: border-box;
    height: 56px;
}

.nav-size-medium {
    height: 48px;
}

.nav-size-medium .item {
    padding: @magritte-core-space-2-x @magritte-core-space-4-x;
}

.item {
    .magritte-typography-label-2-regular();

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: @magritte-core-space-3-x @magritte-core-space-5-x;
    flex: 1 1 auto;
    text-align: center;
    // stylelint-disable declaration-property-value-allowed-list
    color: @magritte-color-component-segmented-item-text-unselected;
    background: @magritte-color-component-segmented-item-background-unselected;
    // stylelint-enable declaration-property-value-allowed-list
    border-radius: @magritte-static-border-radius-200;
    box-shadow: @magritte-shadow-level-0-x @magritte-shadow-level-0-y @magritte-shadow-level-0-blur
        @magritte-shadow-level-0-spread @magritte-shadow-level-0-color;
    transition: @transition-property @magritte-semantic-animation-ease-in-out-tiger-200-duration
        @magritte-semantic-animation-ease-in-out-tiger-200-timing-function;
    box-sizing: border-box;

    &:hover:not(&_selected) {
        // stylelint-disable declaration-property-value-allowed-list
        color: @magritte-color-component-segmented-item-text-state-unselected-hovered;

        // TODO: нет в токенах @magritte-color-component-segmented-item-background-state-unselected-hovered
        background: @magritte-color-component-segmented-item-background-unselected;
        // stylelint-enable declaration-property-value-allowed-list
        transition: @transition-property @magritte-semantic-animation-ease-in-out-tiger-200-duration
            @magritte-semantic-animation-ease-in-out-tiger-200-timing-function;
        cursor: pointer;
    }

    &:active:not(&_selected) {
        transition: @transition-property @magritte-semantic-animation-ease-in-out-tiger-100-duration
            @magritte-semantic-animation-ease-in-out-tiger-100-timing-function;
        transform: scale(@magritte-semantic-scale-pressed);
    }

    &_selected {
        .magritte-typography-subtitle-1-semibold();
        /* stylelint-disable declaration-property-value-allowed-list */
        color: @magritte-color-component-segmented-item-text-selected;
        background: @magritte-color-component-segmented-item-background-selected;
        /* stylelint-enable declaration-property-value-allowed-list */
        box-shadow: @magritte-shadow-level-1-x @magritte-shadow-level-1-y @magritte-shadow-level-1-blur
            @magritte-shadow-level-1-spread @magritte-shadow-level-1-color;
    }

    &:hover,
    &:active,
    &:focus {
        color: inherit;
    }
}
