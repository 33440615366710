.vacancies-list-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.vacancies-list-item-left {
    flex: 1;
    min-width: 0;
}

.overflow-text {
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
