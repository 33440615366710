@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.no-found-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border: 1px solid @magritte-color-base-graphite-90;
    border-radius: 16px;
    padding: 24px;
}

.no-found-data-text-center {
    text-align: center;
}

.no-found-data-img {
    content: '';
    position: absolute;
}

.no-found-data-img-bird::after {
    .no-found-data-img();

    background: right bottom no-repeat url('bird.svg');
    width: 48px;
    height: 48px;
    bottom: 0;
    right: 32px;

    .screen-gt-m({
        width: 80px;
        height: 76px;        
    });
}

.no-found-data-img-cat::after {
    .no-found-data-img();

    background: right bottom no-repeat url('cat.svg');
    width: 68px;
    height: 40px;
    bottom: -19px;
    right: 32px;

    .screen-gt-xs({
        width: 108px;
        height: 63px;        
        bottom: -29px;
    });
}

.no-found-data-col {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 250px;

    .screen-gt-xs({        
        width: 400px;
    });
}

.no-found-data-text {
    background: @magritte-color-base-blue-50;
    border: 1px solid @magritte-color-base-blue-45;
    padding: 8px 12px;
    border-radius: 16px;
    position: relative;
}

.no-found-data-text:last-child {
    border-bottom-right-radius: 0;
}

.no-found-data-text:last-child::after {
    content: '';
    position: absolute;
    right: -6px;
    bottom: -1px;
    width: 22px;
    height: 20px;
    background: no-repeat url('tail.svg');
}
