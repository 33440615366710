@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/spacing-base';

.hiring-survey {
    @media @screen-gt-xs {
        min-width: 360px;
    }
}

.hiring-survey-footer {
    height: 100%;
    gap: @spacing-base;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkbox-wrapper {
    display: flex;
    gap: @spacing-base * 4;
}

.candidate-wrapper {
    display: flex;
    flex-direction: column;
}

.send-button-wrapper {
    align-self: end;
    height: min-content;
}

.other-candidates {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.other-candidates-count-wrapper {
    display: flex;
    padding-top: @spacing-base * 2;
    gap: @spacing-base * 2;
}
