.hidden {
    display: none;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 12px;

    &.column {
        flex-direction: column;
    }
}
