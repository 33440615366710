@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/base-variables';

.controls-container {
    display: flex;
    flex-direction: column;
    gap: @magritte-static-space-300;

    .screen-gt-s({
        flex-direction: row;
    });
}

.controls-text {
    display: flex;
    margin: auto;
}
