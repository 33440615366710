@import '@hh.ru/magritte-ui/base-variables';

.vacancy-system-info {
    display: flex;
    gap: @magritte-static-space-300;
    flex-wrap: wrap;
    align-items: center;
}

.vacancy-system-info-item {
    white-space: nowrap;
}

.vacancy-system-info-expires-auto-prolongation-icon {
    vertical-align: middle;
}
