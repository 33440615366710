@import '@hh.ru/magritte-ui/base-variables';

.vacancy-skill-list {
    display: flex;
    flex-wrap: wrap;
    gap: @magritte-core-space-3-x;
}

.vacancy-skill-list-more {
    cursor: pointer;
}
