@import '@hh.ru/magritte-ui/breakpoints';

.similar-vacancy-list-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 22px;
}

.similar-vacancy-list {
    display: grid;
    gap: 12px;

    .screen-gt-xs({        
        grid-template-columns: repeat(2, 1fr);
    });

    .screen-gt-m({
        grid-template-columns: repeat(3, 1fr);
    });
}
