.dotted-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0 20px;
}

.text {
    position: relative;

    &:empty {
        display: none;
    }
}

.dot {
    &::before {
        content: '.';
        position: absolute;
        left: -12px;
        bottom: 4px;
    }
}

.counting-more {
    display: inline-block;
    white-space: break-spaces;
}
