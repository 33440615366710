@import '@hh.ru/magritte-ui/breakpoints';

.footer-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    // Временное решение, будет исправлено в https://jira.hh.ru/browse/PORTFOLIO-32159
    .screen-gt-s({
        width: 396px;
    });
}

.row-wrapper {
    padding: 12px 0;

    &:last-child {
        padding-bottom: 0;
    }
}
