@import '@hh.ru/magritte-ui/base-variables';

.metric-column-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: @magritte-core-space-1-x;
}

.metric-wrap-mobile {
    display: flex;
    flex-direction: column;
    row-gap: @magritte-core-space-4-x;
}

.metric-row-mobile {
    display: flex;
}

.metric-item-mobile {
    width: 1px; // для выравнивания ширины колонок, фактическая ширина определяется flex-grow
    flex-grow: 1;
}

.metric-wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.metric-info-conversation {
    display: inline-flex;
}

.text-with-legend {
    display: flex;
    align-items: center;
    gap: @magritte-core-space-2-x;
}
