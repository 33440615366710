@import '../../../../../../../node_modules/@hh.ru/magritte-ui/breakpoints';

.additional-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .screen-gt-xs({
        flex-direction: row;
    });
}

.additional-item {
    width: 100%;

    .screen-gt-xs({
        width: 50%;
    });
}
