@import '@hh.ru/magritte-ui/breakpoints';

.card-content {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    .screen-gt-s({
        justify-content: flex-start;
     });
}
