@import '@hh.ru/magritte-ui/breakpoints';

.feedback-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;

    .screen-gt-xs({
        flex-wrap: nowrap
    });
}
