@import '@hh.ru/magritte-ui/base-variables';

.bar-chart-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.bar {
    display: flex;
    flex-direction: column-reverse;
    border-radius: 8px;
    width: 8px;
    height: 128px;
    background-color: @magritte-color-base-graphite-96;
}

.bar-value {
    width: 100%;
    border-radius: 8px;
}

.low {
    background-color: @magritte-color-base-green-75;
}

.medium {
    background-color: @magritte-color-base-orange-75;
}

.high {
    background-color: @magritte-color-base-red-75;
}
