@import '~bloko/common/styles/colors';

.form-wrapper {
    padding: 24px;
    box-sizing: border-box;
}

.date {
    overflow: hidden;
    text-overflow: ellipsis;
}

.date-error {
    color: @color-gray-50;
}
