@import '@hh.ru/magritte-ui/breakpoints';

.footer-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
}

.total-wrap {
    padding: 0 12px;
    width: 100%;
    box-sizing: border-box;
}
